import clickStatStyles from '../components/ui/statistic/statistic.css'
import customToolTipStyles from '../components/ui/statistic/line-chart.css'
import classnames from 'classnames'
import * as theme from '../theme'
import React from 'react'
import pSBC from 'shade-blend-color'

const {
  marketTrendsChartStatTooltipNeighborhoodLabel: neighborhoodLabel,
  marketTrendsChartStatTooltipZipCodeLabel: zipCodeLabel,
  marketTrendsChartStatTooltipCityLabel: cityLabel
} = theme

export function formatMoney(number) {
  const parts = String(Math.round(number)).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const value = '$' + parts.join('.')
  return value
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatDistance(str) {
  const distance = str.length > 1 ? Math.round(str * 10) / 10 : str
  return distance
}

/**
 * The closest() method returns the closest ancestor of the current element (or
 * the current element itself) which matches the selectors given in parameter.
 * If there isn't such an ancestor, it returns null.
 *
 * Polyfill from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Specification
 */
export function closest(element, selector) {
  if ('closest' in element) {
    return element.closest(selector)
  } else {
    const matches = (
      element.document || element.ownerDocument
    ).querySelectorAll(selector)
    let i
    let el = element
    do {
      // eslint-disable-line no-cond-assign
      i = matches.length
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement))
    return el
  }
}

export function onClickToggle(toggleCallback, parentSelector, expand) {
  const bodyListener = (evt) => {
    if (!closest(evt.target, `${parentSelector}`)) {
      document.body.removeEventListener('click', bodyListener)
      toggleCallback(false)
      evt.preventDefault()
      evt.stopPropagation()
    }
  }

  if (expand) {
    document.body.addEventListener('click', bodyListener)
    toggleCallback(true)
  }
}

export function onClickToggleStat(title, evt) {
  const bodyListener = (evt) => {
    if (
      closest(evt.target, `.${clickStatStyles.statisticToggle}`) ||
      !closest(evt.target, `.${clickStatStyles.livebyStatistic}`)
    ) {
      document.body.removeEventListener('click', bodyListener)
      this.setState({ expanded: !this.state.expanded })
      evt.preventDefault()
      evt.stopPropagation()
    }
  }

  if (!this.state.expanded) {
    document.body.addEventListener('click', bodyListener)
    this.setState({ expanded: !this.state.expanded })
  }
}

export function customTooltipContent(a) {
  if (!a.payload || a.payload === null) return

  const tooltipKey = a.payload[0] ? a.payload[0].payload.key : ''
  const formatter = this.props.tooltipFormatter || ((_) => _)

  const labelArr = [
    { title: 'Neighborhood', className: neighborhoodLabel },
    { title: 'Zip Code', className: zipCodeLabel },
    { title: 'City', className: cityLabel }
  ]

  const labels = labelArr.map((label) => {
    const tempTitle = label.title
      .split(' ')
      .map((word) => word.toLowerCase())
      .join('')
    label.value =
      a.payload[0] && a.payload[0].payload[tempTitle] != null
        ? formatter(a.payload[0].payload[tempTitle])
        : 'No Home Sales'

    return {
      title: label.title,
      className: label.className,
      value: label.value
    }
  })

  const tooltipLabels = labels.map((label, idx) =>
    a.payload[0] ? (
      <li key={idx}>
        <span
          className={classnames(
            customToolTipStyles.tooltipLabel,
            label.className
          )}>
          {label.title}:
        </span>
        <span className={customToolTipStyles.tooltipValue}>{label.value}</span>
      </li>
    ) : (
      ''
    )
  )

  return (
    <div>
      <span className={customToolTipStyles.tooltipKey}>{tooltipKey}</span>
      <ul className={customToolTipStyles.expandedTooltip}>{tooltipLabels}</ul>
    </div>
  )
}

export function dasherize(string) {
  return (string || '').replace(/[^\w]+/g, '-')
}

// Dynamically create a link to a stylesheet and add it to the page
export function createStyleSheet(href, appendToBody = false) {
  const cacheBuster = 1 // update this when you want to break the custom CSS cache
  if (href) {
    const link = document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.href = href + '?cb=' + cacheBuster
    link.className = 'liveby-css'
    if (appendToBody && document.getElementsByTagName('body')[0]) {
      document.getElementsByTagName('body')[0].appendChild(link)
    } else {
      document.head.appendChild(link)
    }
    if (window.ShadowRoot) {
      try {
        window.ShadowRoot.appendChild(link)
      } catch {
        // ignore
      }
    }
  }
}

export function createStyleTag(stylesObject, fallbackStylesObject) {
  /*
  {
    themes: {extended: "http://..."},
    colors: {primary: "#FFFFFF"}
  }
  */
  const themes = stylesObject?.themes || fallbackStylesObject?.themes
  const styleString =
    themes && Object.keys(themes).length > 0
      ? Object.values(themes)
          .map((t) => `@import url(${t});`)
          .join(' ')
      : ''

  const theme = {
    // colors
    'color-primary':
      stylesObject?.colors?.primary ||
      fallbackStylesObject?.colors?.primary ||
      '#626279',
    'color-secondary':
      stylesObject?.colors?.secondary ||
      fallbackStylesObject?.colors?.secondary ||
      '#8F91A0',
    'color-tertiary':
      stylesObject?.colors?.tertiary ||
      fallbackStylesObject?.colors?.tertiary ||
      '#B4B4C4',
    'color-cta':
      stylesObject?.colors?.ctaColor ||
      fallbackStylesObject?.colors?.ctaColor ||
      '#FFFFFF',
    'color-success':
      stylesObject?.colors?.success ||
      fallbackStylesObject?.colors?.success ||
      '#89CC89',
    'color-danger':
      stylesObject?.colors?.danger ||
      fallbackStylesObject?.colors?.danger ||
      '#F27574',
    'color-info':
      stylesObject?.colors?.info ||
      fallbackStylesObject?.colors?.info ||
      '#78CFE6',
    'color-warning':
      stylesObject?.colors?.warning ||
      fallbackStylesObject?.colors?.warning ||
      '#F88D48',
    'color-light-gray': '#D2D2D4',
    'color-lightest-gray': '#F9F9F9',
    'color-properties-active':
      stylesObject?.colors?.activeProperties ||
      fallbackStylesObject?.colors?.activeProperties ||
      '#74d473',
    'color-properties-pending':
      stylesObject?.colors?.pendingProperties ||
      fallbackStylesObject?.colors?.pendingProperties ||
      '#f8a542'
  }

  // lightening with hex8 (last 2 digits are opacity), darkening with pSBC
  theme['color-primary-darken'] = pSBC(-0.2, theme['color-primary'])
  theme['color-secondary-darken'] = pSBC(-0.05, theme['color-secondary'])
  theme['color-primary-lighten'] = theme['color-primary'].substring(0, 7) + '20'
  theme['color-secondary-lighten'] =
    theme['color-secondary'].substring(0, 7) + '20'
  theme['color-success-lighten'] = theme['color-success'].substring(0, 7) + '24'
  theme['color-warning-lighten'] = theme['color-warning'].substring(0, 7) + '24'
  theme['color-danger-lighten'] = theme['color-danger'].substring(0, 7) + '24'
  theme['color-info-lighten'] = theme['color-info'].substring(0, 7) + '24'

  const colorString = `
    div.liveby-embed {
      ${Object.entries(theme)
        .map((t) => '--' + t[0] + ': ' + t[1] + ';')
        .join('\n\t')}
    }
  `

  const css = document.createElement('style')
  css.className = 'liveby-css'
  css.appendChild(document.createTextNode(styleString + colorString))
  document.head.appendChild(css)
}

export function clearStyleSheets() {
  const livebyCss = document.querySelectorAll('.liveby-css')
  livebyCss.forEach((css) => {
    css.parentNode.removeChild(css)
  })
}

export function getValidationErrorMessage(type, translate) {
  switch (type) {
    case 'required':
      return translate('forms.errors.this field is required')
    case 'isEmail':
    case 'isPhone':
      return translate('forms.errors.this field is invalid')
  }
}

/**
 * Replaces the last space in a string with a non-breaking space. Use when you want to avoid orphans.
 *
 * @param string
 * @returns {string|*}
 */
export function handleOrphan(string) {
  const lastSpace = string.lastIndexOf(' ')

  return lastSpace
    ? string.substring(string, lastSpace) +
        '\xa0' +
        string.substring(lastSpace + 1)
    : string
}
