/* global fetch, MutationObserver */
import './index.css'
import { createStyleSheet, createStyleTag, clearStyleSheets } from './utility'
import API from './API'
import { load } from 'fathom-client'
import { findTarget } from './utility/componentLoading'

const removeEmpty = (arr) => arr.filter((el) => el != null)
const each = Function.prototype.call.bind(Array.prototype.forEach)
const map = Function.prototype.call.bind(Array.prototype.map)
const flatten = (nestedArrays) => Array.prototype.concat.apply([], nestedArrays)

// Init the application ASAP, even if the DOM is not ready
start()

/**
 * start()
 *
 * This is the first thing that happens when the JavaScript is loaded.
 */
function start() {
  // Timing stuff
  // console.timeEnd('pages.js response time')
  // console.log('Ready state at start(): ', document.readyState)

  // Set up the `window.liveby` object
  const liveby = (window.liveby = Object.assign(
    window.liveby || {
      loaded: () => {}
    },
    {
      initialize,
      deinitialize,
      autoInitialize: isAutoInitialize()
    }
  ))

  // Prevent double initialization
  if (liveby.initialized && liveby.url === document.location.href) {
    console.error('LiveBy script included multiple times')
    return
  } else {
    if (liveby.url) {
      clearStyleSheets()
    }
    liveby.initialized = true
    liveby.url = document.location.href
  }
  if (liveby.autoInitialize) {
    // Initialize the auto-embed stuff
    initialize()
  }
  // Fetch the profile stylesheet (but prioritize the API requests)
  createStyleSheet(`${process.env.PUBLIC_URL}/static/css/main.css`)
}

function isAutoInitialize() {
  return 'liveby' in window && 'autoInitialize' in window.liveby
    ? window.liveby.autoInitialize
    : !getClientIds().filter((c) => 'no-auto-init' in c).length
}

function initialize() {
  // Check for any placeholder elements on the page
  // windemere or LBL as an example
  checkEmbeds()
  // Retrieve the account IDs from the page
  const clientIds = getClientIds()
  // Initialize analytics
  initFathomAnalytics()

  // Fetch the page data for any registered pages
  return Promise.all(clientIds.map(findPages))
    .then(flatten)
    .then(removeEmpty)
    .then(autoEmbed)
}

function deinitialize() {
  const roots = document.querySelectorAll('.liveby-embed')
  each(roots, removeContent)
  window.liveby.initialized = false
  for (const [key, value] of Object.entries(window.liveby.append || {})) {
    value && value.disconnect()
    delete window.liveby.append[key]
  }
  window.liveby.placeholderObserver &&
    window.liveby.placeholderObserver.disconnect()
}

function findPages(embed) {
  const {
    id,
    ref,
    lat,
    lng,
    latVariable,
    lngVariable,
    watchUrl,
    templateId,
    boundaryId
  } = embed
  window.liveby.watchUrl = watchUrl
  watchForUrlChanges()

  if (id && ((lat && lng) || (latVariable && lngVariable))) {
    return fetchPageByCoordinates(embed)
  } else if (templateId && boundaryId) {
    return fetchPageByBoundaryAndTemplate(embed)
  } else {
    return fetchPageData({ id, ref })
  }
}
async function fetchPageByBoundaryAndTemplate({ templateId, boundaryId, id }) {
  const page = await API.getPageByBoundaryAndTemplate({
    templateId,
    boundaryId,
    clientId: id
  })
    .then(getPageEmbeds)
    .catch((e) => {
      console.error(e)
    })
  return page
}

async function fetchPageByCoordinates({
  id,
  lat,
  lng,
  latVariable,
  lngVariable
}) {
  if (latVariable && lngVariable) {
    // eslint-disable-next-line
    lat = eval(latVariable)
    // eslint-disable-next-line
    lng = eval(lngVariable)
  }
  const page = await API.ldpPB({
    lat,
    lng,
    clientid: id
  })
    .then((p) => {
      p.lat = lat
      p.lng = lng
      return p
    })
    .then(getPageEmbeds)
    .catch((e) => {
      // no account found means this customer has been archived, and we don't want to give them content, and don't need to log
      if (e.message != 'No account found') {
        API.logError(id, 'V2 embed error:' + e.message, document.location.href)
      }
      console.error(e)
    })
  return page
}

// Fetch page data for the current client id and page url
async function fetchPageData({ id, ref } = {}) {
  if (!id) return
  const pageDataUrl = `https://${
    process.env.REACT_APP_API_ENDPOINT
  }/v1/pages?id=${id}&ref=${
    ref || encodeURIComponent(document.location.pathname)
  }`
  return fetch(pageDataUrl)
    .then((res) => {
      // Check successful API request
      if (res && res.ok) {
        return res.json()
      } else throw new Error(res.responseText)
    })
    .then((page) => checkPage(page, id))
    .then(getPageEmbeds)
    .catch((e) => {
      // no account found means this customer has been archived, and we don't want to give them content, and don't need to log
      if (e.message != 'No account found') {
        API.logError(id, 'V3 embed error:' + e.message, document.location.href)
      }
      console.error(e)
    })
}

async function getOrWaitForElement(selector) {
  const target = findTarget(selector)
  if (target) {
    return target
  } else {
    return new Promise((resolve, reject) => {
      const mo = new MutationObserver(function () {
        const target = findTarget(selector)
        if (target) {
          resolve(target)
          this.disconnect() // disconnect the observer from the DOM
          if (window.liveby.mo) {
            delete window.liveby.mo[selector]
          }
        }
      })
      mo.observe(document, { childList: true, subtree: true })
      setTimeout(() => {
        mo.disconnect() // disconnect the observer from the DOM
        if (window.liveby.mo) {
          delete window.liveby.mo[selector]
        }
        reject(
          new Error(
            'LiveBy Error: Waited 10 seconds for selector that was never found: ' +
              selector
          )
        )
      }, 10 * 1000)
      window.liveby.mo = { ...window.liveby.mo, [selector]: mo }
    })
  }
}

async function checkPage(page, id) {
  if (!page) {
    const ldpTemplate = await API.ldpTemplate({
      clientid: id
    })
    if (ldpTemplate) {
      if (ldpTemplate.error) {
        console.error(ldpTemplate.message)
        throw new Error(ldpTemplate.message)
      }
      let ldpParams = {}
      if (
        ldpTemplate.properties.latSelector &&
        ldpTemplate.properties.lngSelector
      ) {
        // this needs to get the ldp by lat/lng
        const lat = (
          await getOrWaitForElement(ldpTemplate.properties.latSelector)
        ).innerText
        const lng = (
          await getOrWaitForElement(ldpTemplate.properties.lngSelector)
        ).innerText
        const ldp = await API.ldpPB({
          lat,
          lng,
          clientid: id
        })
          .then((p) => {
            p.lat = lat
            p.lng = lng
            return p
          })
          .catch((e) => {
            console.error(e)
          })
        return ldp
      }
      if (ldpTemplate.properties.listingIdSelector) {
        const textToRemove = ldpTemplate.properties.listingIdTextToRemove
        const el = await getOrWaitForElement(
          ldpTemplate.properties.listingIdSelector
        )
        const fullListingid = el.textContent ? el.textContent : el.value
        ldpParams = {
          ...ldpParams,
          listingid: textToRemove
            ? fullListingid.replace(textToRemove, '').trim()
            : fullListingid
        }
      }
      if (ldpTemplate.properties.fullAddressSelector) {
        const fullAddressElement = await getOrWaitForElement(
          ldpTemplate.properties.fullAddressSelector
        )
        const fullAddress = fullAddressElement.textContent
          ? fullAddressElement.textContent?.replace('\n', '')?.trim()
          : fullAddressElement.value
        ldpParams = { ...ldpParams, fullAddress: fullAddress }
      }
      if (ldpTemplate.properties.addressNoZipSelector) {
        const addressNoZipElement = await getOrWaitForElement(
          ldpTemplate.properties.addressNoZipSelector
        )
        const addressNoZip = addressNoZipElement.textContent
          ? addressNoZipElement.textContent.trim()
          : addressNoZipElement.value
        ldpParams = {
          ...ldpParams,
          addressNoZip: addressNoZip
        }
      }
      // location is: City, State, Zip
      if (ldpTemplate.properties.locationSelector) {
        const locationElement = await getOrWaitForElement(
          ldpTemplate.properties.locationSelector
        )
        const location = locationElement.textContent
          ? locationElement.textContent.trim()
          : locationElement.value
        ldpParams = {
          ...ldpParams,
          location: location
        }
      }
      if (ldpTemplate.properties.streetAddressSelector) {
        const streetAddressElement = await getOrWaitForElement(
          ldpTemplate.properties.streetAddressSelector
        )
        const streetAddress = streetAddressElement.textContent
          ? streetAddressElement.textContent.trim()
          : streetAddressElement.value
        ldpParams = {
          ...ldpParams,
          streetAddress: streetAddress
        }
      }
      if (ldpTemplate.properties.zipcodeSelector) {
        const zipcodeElement = await getOrWaitForElement(
          ldpTemplate.properties.zipcodeSelector
        )
        const zipcode = zipcodeElement.textContent
          ? zipcodeElement.textContent.trim()
          : zipcodeElement.value
        ldpParams = {
          ...ldpParams,
          zipCode: zipcode
        }
      }
      const ldp = await API.ldpByListingId({
        clientid: id,
        ...ldpParams
      })
      return ldp
    }
  } else {
    return page
  }
}

function getPageEmbeds(page) {
  // console.timeEnd('Pages API response time')
  // console.log('Ready state after pages API request: ', document.readyState)
  // Page is not liveby-enabled
  if (!page) return
  if (page.error) {
    console.error('get page embeds', page.error)
    throw new Error(page.message)
  }

  // Add the page data to the window object (is there a better place to stash this?)
  if (!('pages' in window.liveby) || !(page._id in window.liveby.pages)) {
    window.liveby.pages = Object.assign(window.liveby.pages || {}, {
      [page._id]: page
    })
  }
  createStyleTag(page?.template?.adminStyles, page?.account?.styles)
  if (page.template.styles) {
    createStyleSheet(page.template.styles, true)
  }
  page.template.properties &&
    createStyleSheet(page.template.properties.customStyles, true)
  // Return the array of embeds for the current page
  // I don't like splitting here,... but it works.
  return page.pageType === 'directory'
    ? page.template.contentareas.map(
        ({ selector, attachment, styles, _id }, idx) => ({
          selector,
          attachment,
          brokerage: page.account.shortname,
          city: page.city + ', ' + page.state
        })
      )
    : page.template.contentareas.map(
        ({ selector, attachment, styles, _id }, idx) => ({
          selector,
          attachment,
          contentarea: _id,
          page: page._id,
          contentareaidx: idx
        })
      )
}

// Add necessary auto-embed placeholders and initialize them
function autoEmbed(embeds) {
  // Add the auto-embed placeholder elements
  if (embeds && embeds.length) {
    // Add all features to each embed once we know what embeds will be here
    // We can begin rendering the profile as long as we have document.body
    watchForElement('body', () => {
      embeds.forEach(initAutoEmbed)
    })
  }
}

/* Make sure the url doesn't change, and if it does, re-render our page */
function watchForUrlChanges() {
  if (window.liveby.watchUrl && !window.liveby.intervalId) {
    window.liveby.intervalId = setInterval(() => {
      if (window.location.href !== window.liveby.url) {
        window.liveby.url = window.location.href
        clearInterval(window.liveby.intervalId)
        delete window.liveby.intervalId
        window.liveby.pages = {}
        for (const key in window.liveby.append) {
          window.liveby.append[key].disconnect()
          delete window.liveby.append[key]
        }
        for (const key in window.liveby.watcher) {
          window.liveby.watcher[key].disconnect()
          delete window.liveby.watcher[key]
        }
        window.liveby.placeholderObserver.disconnect()
        delete window.liveby.placeholderObserver

        const livebyDiv = document.querySelectorAll('.liveby-embed')
        livebyDiv.forEach((div) => {
          div.dataset.liveby && div.remove()
        })
        initialize()
      }
    }, 500)
  }
}

// Initialization for pages
async function initPage(root) {
  const [{ React, ReactDOM, LocalizeProvider }, { default: Profile }] =
    await Promise.all([import('./render-dependencies'), import('./Profile')])

  const { page: p, contentareaidx } = root.dataset
  const page = window.liveby.pages[p]
  const render = (props, cb) => {
    ReactDOM.render(
      <LocalizeProvider>
        <Profile {...props} />
      </LocalizeProvider>,
      root,
      cb
    )
  }
  const props = setupInitialProps(page, contentareaidx)
  watchForUrlChanges()
  // console.time('Profile render time')
  render(props, () => {
    // console.timeEnd('Profile render time')
    // console.log('Ready state after profile render: ', document.readyState)
    // console.timeEnd('Time after DOMContentLoaded')
  })

  // Fetch the neighborhood geometry
  if (page.boundary && !props.neighborhood.geometry) {
    // console.time('Geometry fetch time')
    API.neighborhoodGeoJSON(page.boundary._id).then((response) => {
      // console.timeEnd('Geometry fetch time')
      props.neighborhood = Object.assign({}, props.neighborhood, {
        geometry: response.data.geometry,
        meta: response.data.meta
      })
      render(props)
      try {
        window.liveby.loaded({ page, ...props })
      } catch (err) {}
    })
  } else {
    try {
      window.liveby.loaded({ page, ...props })
    } catch (err) {}
  }
}

async function initPageEmbed(root) {
  const [{ React, ReactDOM, LocalizeProvider }, { default: Profile }] =
    await Promise.all([import('./render-dependencies'), import('./Profile')])

  const { url, brokerage } = root.dataset
  const embeds = await fetchPageData({ ref: url, id: brokerage })
  if (!embeds) return
  const page = window.liveby.pages[embeds[0].page]
  if (!page) return
  if (page.error) {
    return
  }
  if (root.dataset.features) {
    page.template.contentareas = [
      {
        components: root.dataset.features.split(',').map((name) => ({ name }))
      }
    ]
  }
  const render = (props, cb) => {
    ReactDOM.render(
      <LocalizeProvider>
        <Profile {...props} />
      </LocalizeProvider>,
      root,
      cb
    )
  }
  const props = setupInitialProps(page, 0)
  watchForUrlChanges()
  // console.time('Profile render time')
  render(props, () => {})

  // Fetch the neighborhood geometry
  if (page.boundary && !props.neighborhood.geometry) {
    // console.time('Geometry fetch time')
    API.neighborhoodGeoJSON(page.boundary._id).then((response) => {
      // console.timeEnd('Geometry fetch time')
      props.neighborhood = Object.assign({}, props.neighborhood, {
        geometry: response.data.geometry,
        meta: response.data.meta
      })
      render(props)
      try {
        window.liveby.loaded({ page, ...props })
      } catch (err) {}
    })
  } else {
    try {
      window.liveby.loaded({ page, ...props })
    } catch (err) {}
  }
}

// Restructure the page data to conform with the way we currently do profiles
function setupInitialProps(page, area) {
  const pageProperties = pick(
    page,
    'city',
    'state',
    'child_pages',
    'child_pages_sort',
    'bio',
    'tags',
    'banner',
    'banner_16x9',
    'banner_img_16x9',
    'photos_description',
    'photos',
    'photo_library',
    'parentPage',
    'language',
    'url',
    'lat',
    'lng'
  )

  const allFeatures = page.template.contentareas
    .map((area) => area.components.map((c) => c.name))
    .reduce((a, b) => a.concat(b), [])

  const boundary = page.boundary || { properties: {} }
  const props = {
    ...(page.account?.properties || {}),
    ...(page.template?.properties || {}),
    ...(page?.properties || {}),
    ...pageProperties,
    neighborhood: {
      ...boundary,
      properties: {
        ...boundary.properties,
        label: page.name,
        community_type: page.pageType,
        address: {
          ...boundary.properties.address,
          city: pageProperties.city,
          state: pageProperties.state
        },
        ...pageProperties
      }
    },
    brokerage: page.account,
    template: page.template,
    components: page.template.contentareas[area].components,
    features: page.template.contentareas[area].components.map((c) => c.name),
    selector: page.template.contentareas[area].selector,
    allFeatures
  }
  return props
}

function getQueryKey({
  id,
  neighborhood: label,
  cache,
  lat,
  lng,
  brokerage,
  allowZipcode,
  types = 'neighborhood',
  boundaryId,
  templateId
}) {
  if (id) {
    return `${id}-${label}${cache ? Date.now() : ''}`
  } else if (lat && lng) {
    return `${lat}-${lng}-${brokerage}-zip-${allowZipcode}-types-${types}`
  } else if (templateId && boundaryId) {
    return `${templateId}-${boundaryId}-${brokerage}-types-${types}`
  } else if (boundaryId) {
    return `${boundaryId}`
  } else {
    return ''
  }
}

function getQuery({
  id: polyline,
  neighborhood: label,
  cache,
  lat,
  lng,
  brokerage,
  types,
  allowZipcode,
  templateId,
  boundaryId
}) {
  if (polyline) {
    return API.neighborhoodPolyline({
      polyline,
      label: encodeURIComponent(
        label.replace('+', ' ') + (cache ? '?' + Date.now() : '')
      )
    }).then((data) => {
      return data.data
    })
  } else if (lat && lng) {
    return API.local({
      lat,
      lng,
      clientid: brokerage,
      allowZipcode,
      types
    }).then((n) => {
      if (n && n.data) {
        return n.data
      } else {
        throw new Error('no neighborhood found')
      }
    })
  } else if (templateId && boundaryId) {
    return API.getPageByBoundaryAndTemplate({
      clientId: brokerage,
      templateId,
      boundaryId
    }).then((page) => {
      if (page && !page.error) {
        return page.boundary
      } else {
        throw new Error('no neighborhood found')
      }
    })
  } else if (boundaryId) {
    return API.neighborhood(boundaryId).then((data) => {
      return data.data
    })
  }
}

// Initialize a neighborhood profile with id/neighborhood
async function initProfile(root, options) {
  const [{ React, ReactDOM, LocalizeProvider }, { default: Profile }] =
    await Promise.all([import('./render-dependencies'), import('./Profile')])
  const features =
    root.dataset && root.dataset.features
      ? root.dataset.features.split(',').map((f) => f.trim())
      : []
  const fullOpts = await parseOptions(Object.assign({}, options, root.dataset))

  let neighborhoodPromise = Promise.resolve()
  window.liveby.watchUrl = fullOpts.watchUrl
  const queryKey = getQueryKey(fullOpts)
  window.liveby.requestCache = window.liveby.requestCache || {}
  const requestCache = window.liveby.requestCache || {}
  if (requestCache[queryKey]) {
    neighborhoodPromise = requestCache[queryKey]
  } else {
    neighborhoodPromise = requestCache[queryKey] = getQuery(fullOpts)
  }
  watchForUrlChanges()
  const render = (props) => {
    ReactDOM.render(
      <LocalizeProvider>
        <Profile {...props} />
      </LocalizeProvider>,
      root
    )
  }

  const allFeatures = root.dataset.allFeatures.split(',').map((f) => f.trim())
  const currentData = {
    ...fullOpts,
    neighborhood: fullOpts.label,
    features,
    allFeatures,
    brokerage: { shortname: fullOpts.brokerage }
  }

  const n = await neighborhoodPromise
  currentData.neighborhood = n

  render(currentData)
  const account = await fetch(
    `https://${process.env.REACT_APP_API_ENDPOINT}/v1/pages/account?id=${fullOpts.brokerage}`
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log('Caught error querying API')
      console.log(e)
    })
  if (account) {
    // potentially use lbl colors as fallback if possible
    createStyleTag(account?.styles, {
      colors: {
        primary: account?.reportsguides?.primaryColor,
        secondary: account?.reportsguides?.secondaryColor
      }
    })
    currentData.brokerage = account
    render({ ...(account.properties || {}), ...currentData })
  }
}

async function parseOptions(opts, retry = 20) {
  const { latVariable, lngVariable } = opts
  if (latVariable && lngVariable) {
    try {
      // eslint-disable-next-line
      const lat = eval(latVariable),
        lng = eval(lngVariable)
      return {
        ...opts,
        lat,
        lng
      }
    } catch (e) {
      if (retry) {
        // Wait 500 ms, then retry
        await new Promise((resolve) => setTimeout(resolve, 500))
        return parseOptions(opts, retry - 1)
      } else {
        console.error(e)
        fetch(
          `https://${process.env.REACT_APP_API_ENDPOINT}/v1/client-error-reporter`,
          {
            method: 'POST',
            body: JSON.stringify({
              error: `${e.stack}\n\nIn parsing lat/lng val:\n${latVariable}\n${lngVariable}`
            })
          }
        )
      }
    }
  }
  return opts
}

async function initProfileList(root) {
  const [{ React, ReactDOM, LocalizeProvider }, { default: LandingPage }] =
    await Promise.all([
      import('./render-dependencies'),
      import('./components/landing-page')
    ])

  const { city: cityState, brokerage, layout } = root.dataset
  const [city, state] = cityState.split(',').map((s) => s.trim())

  const render = (props) => {
    ReactDOM.render(
      <LocalizeProvider>
        <LandingPage {...props} initializeLanguage />
      </LocalizeProvider>,
      root
    )
  }

  const neighborhoodLookup = (endpoint) =>
    fetch(
      `https://${process.env.REACT_APP_API_ENDPOINT}/v1/neighborhood-brokerage-lookup/${endpoint}?brokerage=${brokerage}&city=${city}&state=${state}`
    ).then((res) => {
      if (res.ok) return res.json()
      else throw new Error(res.statusText)
    })

  // Initial (empty) render prior to API request
  render()

  // Request neighborhood info
  neighborhoodLookup('pages')
    .then((pages) => {
      render({ pages, layout })
      // Now fetch property info in a separate request.
      return neighborhoodLookup('page-property-info').then((propertyInfo) => {
        render({ pages, layout, propertyInfo })
      })
    })
    .catch((err) => {
      console.log(
        'Caught error looking up the neighborhood list for the brokerage'
      )
      console.log(err)
    })

  // Add landing page CSS dependencies
  createStyleSheet(
    'https://cdnjs.cloudflare.com/ajax/libs/react-select/1.0.0-rc.10/react-select.min.css'
  )
}

function initEmbed(root) {
  if (!window.liveby.loggedVisit) {
    const clientid = root.dataset.brokerage || getClientIds()?.[0]?.id
    const domain = window.location.hostname
    API.clientDomain(clientid, domain)
    window.liveby.loggedVisit = true
  }
  if (!Object.hasOwnProperty.call(root, 'livebyInitialized')) {
    root.livebyInitialized = true
    const rootOptions = root.dataset
    const scripts = getLiveByScript()
    const opts = {
      ...scripts.reduce((s, e) => ({ ...s, ...e.dataset }), {}),
      ...rootOptions
    }
    if ('page' in rootOptions) {
      initPage(root)
    } else if ('id' in opts || 'lat' in opts || 'latVariable' in opts) {
      initProfile(root, opts)
    } else if ('templateId' in opts && 'boundaryId' in opts) {
      initProfile(root, opts)
    } else if ('boundaryId' in opts) {
      initProfile(root, opts)
    } else if ('city' in rootOptions) {
      initProfileList(root)
    } else if ('url' in rootOptions) {
      initPageEmbed(root)
    } else {
      // no community page found, check to see if it's an LDP
    }
    createStyleSheet(opts.styles)
  }
}

function initFathomAnalytics() {
  if (process.env.NODE_ENV === 'production') {
    load('ZGTWRZTY')
  }
}

function getLiveByScript() {
  return map(document.querySelectorAll('script[src*="liveby.com"]'), (s) => s)
}

function initAutoEmbed({
  selector,
  attachment = 'append',
  placeholder = document.createElement('div'),
  ...config
}) {
  placeholder.className = 'liveby-embed'
  for (const key in config) {
    placeholder.setAttribute('data-' + key, config[key])
  }
  placeholder.setAttribute('data-liveby', true)

  initEmbed(placeholder)

  // Watch in case something tries to remove LiveBy from DOM (like WIX)
  if (
    (window.liveby.pages[config.page].properties &&
      window.liveby.pages[config.page].properties.keepLiveByRendered) ||
    (window.liveby.pages[config.page].template.properties &&
      window.liveby.pages[config.page].template.properties.keepLiveByRendered)
  ) {
    new MutationObserver(function (events) {
      if (placeholder.children.length === 0) {
        // Restart the placeholder to fix react and LiveBy embed
        placeholder.remove()
        placeholder = document.createElement('div')
        this.disconnect()
        initAutoEmbed({ ...config, selector, attachment, placeholder })
      }
    }).observe(placeholder, { childList: true })
  }
  watchForElement(selector, (holder) => {
    watchForRemoval(holder, selector, () =>
      initAutoEmbed({ ...config, selector, attachment, placeholder })
    )
    if (attachment === 'replace') {
      persistentReplace(holder, placeholder, selector)
    } else if (attachment === 'append') {
      persistentAppend(holder, placeholder, selector)
    } else if (attachment === 'prepend') {
      persistentPrepend(holder, placeholder, selector)
    } else if (attachment === 'after') {
      persistentAfter(holder, placeholder, selector)
    } else if (attachment === 'before') {
      persistentBefore(holder, placeholder, selector)
    } else {
      // Simple `appendChild` if we do not recognize the attachment method
      appendContent(holder, placeholder)
    }
  })
}

// checkEmbeds - detect any placeholder elements on the page
function checkEmbeds() {
  // Immediately check for placeholder elements on the page
  initEmbeds()
  // ...and check after any mutation events
  const mutationObserver = new MutationObserver(initEmbeds)
  window.liveby.placeholderObserver = mutationObserver
  mutationObserver.observe(document, { childList: true, subtree: true })
  // ...when the DOM is finished loading,
  document.addEventListener('DOMContentLoaded', () => {
    // ...disconnect the mutation listener
    mutationObserver.disconnect()
    // ...and check one last time just to be safe (should not be necessary)
    initEmbeds()
  })
}

function initEmbeds() {
  const roots = document.querySelectorAll('.liveby-embed')
  each(roots, addFullFeature(roots))
  each(roots, initEmbed)
}

function addFullFeature(roots) {
  return function (root) {
    root.dataset.allFeatures = map(roots, (r) => r.dataset.features)
  }
}

async function removeContent(root) {
  root.dataset.page && root.remove()
  delete root.livebyInitialized
  const { ReactDOM } = await import('./render-dependencies')
  ReactDOM.unmountComponentAtNode(root)
}

// Retrieve the client ID from the `id` parameter
function getClientIds() {
  return map(getLiveByScript(), (s) => s)
    .filter((s) => s.src && String(s.src).indexOf('id=') > -1)
    .map(parseUrlParams)
    .filter(uniqueEmbeds())
}

// Remove duplicate `id` and `ref` combinations
function uniqueEmbeds() {
  const uniques = {}
  return function ({ id, ref }) {
    const key = JSON.stringify({ id, ref })
    if (key in uniques) {
      return false
    } else {
      return (uniques[key] = true)
    }
  }
}

// Return a new object consisting of `props` picked from `obj`
function pick(obj, ...props) {
  const o = {}
  for (let i = 0, len = props.length; i < len; i++) {
    o[props[i]] = obj[props[i]]
  }
  return o
}

// Quick & dirty URL query parser. Requires that the `ref` attribute (if present) be url-encoded using `encodeURIComponent(url)`
function parseUrlParams({ src: url = '', dataset }) {
  const parts = (url.split('?')[1] || '').split('&')
  return parts.reduce((o, p) => {
    const [key, value] = p.split('=')
    return { ...o, [key]: value != null ? decodeURIComponent(value) : '' }
  }, Object.assign({}, dataset))
}

// watchForElement - Fire `callack` when a DOM element is available in the page.
function watchForElement(selector, callback) {
  try {
    const target = findTarget(selector)
    if (target) {
      return callback(target)
    } else {
      const mo = new MutationObserver(function () {
        const target = findTarget(selector)
        if (target) {
          callback(target)
          this.disconnect() // disconnect the observer from the DOM
        }
      })
      mo.observe(document, { childList: true, subtree: true })
      window.liveby.mo = { ...window.liveby.mo, [selector]: mo }
    }
  } catch (e) {
    console.error(e)
  }
}

function watchForRemoval(target, selector, callback) {
  const url = document.location.href
  function handleEvents(r) {
    for (const ev of r) {
      try {
        if (ev.type === 'childList') {
          for (const node of ev.removedNodes) {
            if (node.contains(target)) {
              removeContent(target)
              window.liveby.append[selector].disconnect()
              delete window.liveby.append[selector]
              if (document.location.href === url) {
                // Make sure this is still the same page
                callback()
              }
              watcher.disconnect()
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const watcher = new MutationObserver(handleEvents)
  window.liveby.watcher = {
    ...window.liveby.watcher,
    [selector]: watcher
  }
  watcher.observe(document, {
    childList: true,
    subtree: true
  })
}

// Remove any existing content and replace with new content
function replaceContent(parent, child) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild)
  }
  appendContent(parent, child)
}

function appendContent(parent, child) {
  parent.appendChild(child)
}

function prependContent(parent, child) {
  parent.insertBefore(child, parent.firstChild)
}

function placeContentAfter(parent, child) {
  parent.parentNode.insertBefore(child, parent.nextSibling)
}

function placeContentBefore(parent, child) {
  parent.parentNode.insertBefore(child, parent)
}

// persistentAppend - append `child` to `parent` and ensure that it stays last in the container
function persistentAppend(parent, child, selector) {
  appendContent(parent, child)

  dontLetLCPGetRemoved(child, parent, selector, appendContent)
}

function persistentReplace(parent, child, selector) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild)
  }
  appendContent(parent, child)

  dontLetLCPGetRemoved(child, parent, selector, replaceContent)
}

function persistentPrepend(parent, child, selector) {
  prependContent(parent, child)

  dontLetLCPGetRemoved(child, parent, selector, prependContent)
}

function persistentAfter(parent, child, selector) {
  placeContentAfter(parent, child)

  dontLetLCPGetRemoved(child, parent, selector, prependContent)
}

function persistentBefore(parent, child, selector) {
  placeContentBefore(parent, child)

  dontLetLCPGetRemoved(child, parent, selector, prependContent)
}

function dontLetLCPGetRemoved(child, parent, selector, callback) {
  if (window.liveby.autoInitialize) {
    // Listen to DOM additions and re-append if necessary
    const observer = new MutationObserver(function observer(mutations) {
      let reappend = false
      for (let i = 0, len = mutations.length; i < len; i++) {
        const mutation = mutations[i]
        if (
          mutation.previousSibling === child &&
          mutation.addedNodes.length > 0
        ) {
          reappend = true
        }
        if (
          mutation.removedNodes.length > 0 &&
          Array.from(mutation.removedNodes).includes(child)
        ) {
          reappend = true
        }
      }
      if (reappend && !parent.contains(child)) {
        callback(parent, child)
      }
    })
    observer.observe(parent, { childList: true })
    if (!window.liveby.append) {
      window.liveby.append = {}
    }
    window.liveby.append = {
      ...window.liveby.append,
      [selector]: observer
    }
  }
}
